<template>
    
    <div class="mt-2 mx-2 rounded-lg flex flex-col overflow-hidden" :class="{'h-10': !messageopen, 'h-72': messageopen, 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}" style="transition: all .4s;">

        <div v-if="datamessage" class="h-10 flex-none p-1 w-full flex flex-row justify-start items-center px-2" @click="messageopen = !messageopen">

            <img :src="'/img/'+ mode +'/messages.svg'" class="h-4">

            <span class="text-xs ml-2 mr-auto" :class="'text-dfont-'+mode">{{ $t('mensajes') }}</span>

            <span class="text-xs" :class="'text-font_gray-'+mode">{{ datamessage.length }}</span>

        </div>

        <div class="h-full">

            <All :model="endpoint" :immediate="false" :query="querymessage" :data.sync="datamessage" v-slot="{data:message, loading:loadingmessage}" ref="all">

                <div v-if="messageopen && !loadingmessage" class="h-full overflow-hidden p-1">
                    
                        <div v-if="!loadingmessage" class="h-full overflow-auto">

                            <message v-for="(el,index) in message" :key="index" :data="el" @messageread="messageread"></message>

                            <div v-if="message.length == 0" class="h-full w-full flex flex-row justify-center items-center">

                                <span class="text-xs" :class="'text-dfont-'+mode">{{$t('noNewMessage')}}</span>

                            </div>

                        </div>

                </div>

            </All>
        
        </div>

    </div>

</template>

<script>
import { All, Request } from '@/api/components';
import message from './message.vue';
import loader from './loader.vue';
import { state, actions } from '@/store';

export default {
    
    props:['endpoint', 'querymessage'],
    components:{
        All,
        Request,
        message,
        loader
    },
    data(){

        return{

            messageopen:false,
            datamessage:null

        }

    },
    methods:{

        messageread(){

            this.$refs['all'].request();

        }

    },
    computed:{
        mode(){
            return state.mode
        }
    },
    mounted(){

        this.$refs['all'].request();

    }

}
</script>