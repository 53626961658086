<template>
    
    <div class="h-auto mb-1 w-full rounded-lg flex flex-row shadow" :class="'bg-module-'+mode">
                            
        <div class="h-full w-10/12 p-2 break-words">
        
            <span class="text-2xs text-gray mr-1">{{dates}}</span>
            <span class="text-sm" :class="'text-dfont-'+mode">{{data.message}}</span>
        
        </div>

        <div class="h-full w-2/12 flex flex-row justify-center items-center my-auto">
        
            <Request :form="form" model="Updatemessage" action="update" @error="onError" @success="onSuccess" v-slot="{ request }">

                <i @click="request" class="mdi mdi-delete text-red text-sm"></i>

            </Request>

        </div>

    </div>

</template>

<script>
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';

export default {
    props:['data'],
    components:{
        All,
        Request
    },
    data(){
        return{
            today: this.$moment().format('DD/MM/YYYY')
        }
    },
    methods:{

        onSuccess(){

            this.$emit('messageread')

        },
        onError(error){

            console.log(error)

        }

    },
    computed:{
        mode(){
            return state.mode
        },
        form(){
            return{
                id: this.data.id,
                checked: 1,
                Company: this.data.Company
            }
        },
        dates(){

            if(this.today  ==  this.$moment(this.data.created_at).format('DD/MM/YYYY')){

                return this.$moment(this.data.created_at).format('HH:mm')

            } else {

                return this.$moment(this.data.created_at).format('DD/MM/YYYY')

            }

        }

    }
}
</script>