<template>

    <div class="w-full h-full flex flex-col overflow-hidden">

        <div class="h-auto w-full overflow-auto px-3 py-4 flex-col">

            <All :model="infomodel" :immediate="false" :config="config" v-slot="{data: infodata, loading: loadinginfo}" ref="zone">

                <div v-if="!loadinginfo" class="h-auto w-full rounded-lg shadow_module flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

                    <readmessages v-if="permisions && !comercialSelected" endpoint="Comercialmessage" :querymessage="querymessage" />
                    
                    <All :model="modelComercial" :immediate="true" :config="config" :query="query" v-slot="{data, loading}">

                        <div v-if="!loading" class="h-auto pb-2">

                            <div v-if="!preciomedio && !margen" class="h-auto w-full"  :class="{'grid grid-cols-2 gap-1': !facturation && !metroscuadrados}">
                                <billing v-if="!metroscuadrados" :data="data.BubbleSale" :model="model" :flag="false"
                                    :config="configFacturation" @openFacturation="openFacturation" :lastSale="infodata.LastSaleDate" />
                                <meters v-if="!facturation" :data="data.BubbleMeter" :model="model" :config="configMetros" :flag="false"
                                    @openMeters="openMeters" :lastSale="infodata.LastSaleDate" />
                            </div>

                            <div v-if="!facturation && !metroscuadrados" class="h-auto w-full mt-2"  :class="{'grid grid-cols-2 gap-1': !margen && !preciomedio}">
                                <halfprice v-if="!margen" :data="data.BubbleMediumPrice" :model="model" :config="configPreciomedio"
                                    @fromhalfprice="openPreciomedio" :isOpen="preciomedio" :lastSale="infodata.LastSaleDate" />
                                <margin v-if="!preciomedio" :data="data.BubbleMargin" :model="model" :config="configMargen"
                                    @openMargen="openMargen" :isOpen="margen" :lastSale="infodata.LastSaleDate" />
                            </div>

                        </div>

                        <div v-else class="h-32 relative rounded-lg shadow_box" :class="'bg-module-'+mode">
                            <loader :loading="loading" />
                        </div>
                        
                    </All>

                </div>

                <div v-else class="h-32 relative rounded-lg shadow_box" :class="'bg-module-'+mode">
                    <loader :loading="loadinginfo" />
                </div>
                
            </All>

            <div v-if="comercialSelected" class="h-auto mt-4">
                <All model="GroupZonesComercial" :immediate="true" :query="querygroupzones" v-slot="{data: zones, loading: loadingzones}">
                    <div v-if="!loadingzones" class="h-auto">
                        <!-- 24C8C714394211EA966B005056AEAA71 = nacional -->
                        <zone v-for="(el, index) in zones['24C8C714394211EA966B005056AEAA71']" :key="index" :data="el" :rol="user.rol.Name"
                            :dimensionName="dimensionName" lastpage="zone" @openZone="openZone" zonetype="24C8C714394211EA966B005056AEAA71" />
                        <!-- 24C85D60394211EA966B005056AEAA71 = exportación -->
                        <zone v-for="(el, index) in zones['24C85D60394211EA966B005056AEAA71']" :key="index" :data="el" :rol="user.rol.Name"
                            :dimensionName="dimensionName" lastpage="zone" @openZone="openZone" zonetype="24C85D60394211EA966B005056AEAA71" />
                    </div>
                </All>
            </div>

            <div class="h-20"></div>
        
        </div>

        <div class="absolute w-full bottom-0 left-0 h-18">

            <div class="h-full flex flex-row p-2 bg-transparent" :class="{'w-full': mobile, 'w-1/2 m-auto': !mobile}">

                <div class="h-full w-full px-4 space-x-6 rounded-2xl flex flex-row justify-start items-center shadow_box" :class="'bg-module-'+mode">
                
                    <div class="h-full w-full overflow-hidden flex flex-col justify-center items-center cursor-pointer">
                        <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="toKpis()">
                            <!-- <i class="mdi mdi-chart-line-variant" :class="'text-dfont-'+mode"></i> -->
                            <img :src='"/img/kpis_" + mode + ".svg"' class="h-4">
                        </div>
                        <span class="text-2xs font-medium" :class="'text-dfont-'+mode">KPIs</span>
                    </div>

                    <div class="h-full w-full overflow-hidden flex flex-col justify-center items-center cursor-pointer">
                        <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="toVisits()">
                            <img :src='"/img/visits_" + mode + ".svg"' class="h-4">
                        </div>
                        <span class="text-2xs font-medium" :class="'text-dfont-'+mode">{{ $t('visits') }}</span>
                    </div>

                    <div class="h-full w-full overflow-hidden flex flex-col justify-center items-center cursor-pointer">
                        <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="toClients()">
                            <img :src='"/img/clients_" + mode + ".svg"' class="h-4">
                        </div>
                        <span class="text-2xs font-medium" :class="'text-dfont-'+mode">{{ $t('customers') }}</span>
                    </div>

                    <div class="h-full w-full overflow-hidden flex flex-col justify-center items-center cursor-pointer">
                        <div class="h-8 w-8 rounded-full border-2 flex flex-col justify-center items-center" :class="'border-dfont-'+mode" @click="toRepresentants()">
                            <img :src='"/img/representant_" + mode + ".svg"' class="h-4">
                        </div>
                        <span class="text-2xs font-medium" :class="'text-dfont-'+mode">{{ getReduceName($t('representatives')) }}</span>
                    </div>
                
                </div>

                <div v-if="rolpermisions && !comercialSelected" class="h-auto w-auto mx-4 flex flex-col justify-center items-center absolute right-0 bottom-1" :class="{'': !mobile, '-mt-16': mobile}">

                    <div class="h-12 w-12  rounded-full bg-red flex flex-col justify-center items-center" @click="toTops()">
                        <i class="mdi mdi-filter-variant text-white text-2xl font-semibold"></i>
                    </div>

                </div>
            
            </div>

        </div>

    </div>

</template>

<script>
    import { state,actions } from '@/store';
    import solidgauge from '@/components/solidgauge.vue';
    import { All } from '@/api/components';
    import loader from '@/components/loader.vue';
    import billing from '@/components/billing.vue';
    import meters from '@/components/meters.vue';
    import halfprice from '@/components/halfprice.vue';
    import margin from '@/components/margin.vue';
    import readmessages from '@/components/readmessages.vue';
    import zone from '@/components/zone.vue';

    export default {
        name: 'zone_',
        components: {
            solidgauge,
            All,
            loader,
            billing,
            meters,
            halfprice,
            margin,
            readmessages,
            zone
        },
        data() {
            return {
                company: null,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                monthprev: this.$moment().subtract(1, 'months').format("MMM 'YY"),
                preciomedio: false,
                margen: false,
                facturation: false,
                metroscuadrados: false
            }
        },
        computed: {
            periodo() {
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            user(){ 
                return state.user 
            },
            config() {
                if (this.modelComercial === 'ComercialZone') {
                    if (state.filterZoneComercial == null) {
                        return {
                            data: {
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.idZone,
                                // IdUser: state.userId,
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.idZone,
                                // IdUser: state.userId,
                            }
                        }
                    }
                }
                else
                    if (this.modelComercial === 'ComercialUser') {
                        return {
                            data: {
                                Company: state.idCompany,
                                // SaleType: state.saleTypeSelected,
                                IdUser: state.comercialSelected,
                            }
                        }
                    }
            },
            modelComercial() {
                if (state.comercialSelected) { return 'ComercialUser' }
                else {
                    if (state.comercialSelected && state.comercialSelected.Id && state.filterZoneComercial == null) {
                        return 'ComercialUser'
                    }
                    else { return 'ComercialZone' }
                }

            },
            configFacturation() {
                if (state.comercialSelected) {
                    return {
                        data: {
                            Dimension: 0,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            period: state.period,
                        },
                    };
                }
                else {
                    return {
                        data: {
                            Dimension: 0,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            Zone: state.idZone,
                            period: state.period,
                            SaleType: state.saleTypeSelected,
                        },
                    };
                }
            },
            configMetros() {
                if (state.comercialSelected) {
                    return {
                        data: {
                            Dimension: 1,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            period: state.period,
                        },
                    }
                }
                else {
                    return {
                        data: {
                            Dimension: 1,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            period: state.period
                        }
                    }
                }
            },
            configPreciomedio() {
                if (state.comercialSelected) {
                    return {
                        data: {
                            Dimension: 2,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            period: state.period,
                        },
                    };
                }
                else {
                    return {
                        data: {
                            Dimension: 2,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            period: state.period
                        }
                    }
                }
            },
            configMargen() {
                if (state.comercialSelected) {
                    return {
                        data: {
                            Dimension: 3,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            period: state.period,
                        }
                    }
                }
                else {
                    return {
                        data: {
                            Dimension: 3,
                            Company: state.idCompany,
                            IdUser: state.userId,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            period: state.period
                        }
                    }
                }
            },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: state.industrialGroup,
                    month: state.month
                }
            },
            zone(){
                return state.zoneSelected
            },
            model() {
                if (state.comercialSelected) { return 'ComercialbubbledetailUser' }
                else {
                    if ((state.comercialSelected && state.comercialSelected.Id) || state.idZone == null) {
                        return 'ComercialbubbledetailUser'
                    }
                    else { return 'ComercialbubbledetailZone' }
                }
            },
            zonefiltered(){ 
                return state.filterZoneComercial
            },
            infomodel() {
                if (state.comercialSelected) { return 'ComercialInfoUser' }
                else { return 'ComercialInfoZone' }
            },
            zonasel(){
                return state.filterZoneComercialName
            },
            saletype(){
                return state.saleTypeSelected
            },
            saletypename(){
                return state.zoneSelected.substr(0,3)
            },
            querymessage() {
                if (state.user.rol.Name !== 'Comercial') {
                    return {
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        Zone: state.idZone
                    }
                }
                else {
                    return {
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        Zone: state.idZone
                    }
                }
            },
            permisions() {
                if (state.user.rol.Name === 'Comercial') { return true }
                else
                    if (state.user.rol.Name === 'Gerente') { return true }
                    else { return false }
            },
            rol(){ 
                return state.user.rol.Name 
            },
            rolpermisions() {
                if (this.rol === 'Propiedad') { return true }
                else
                    if (this.rol === 'Gerente') { return true }
                    else
                        if (this.rol === 'Comercial') { return true }
                        else { return false }
            },
            actualZone(){ 
                return state.zone 
            },
            comercial(){ 
                return state.zone
            },
            path(){ 
                return this.$route.name
            },
            comercialSelected(){ 
                return state.comercialSelected 
            },
            querygroupzones() {
                return {
                    Company: state.idCompany,
                    IdUser: state.user.id,
                    period: state.period,
                    Dimension: state.dimensionGroup
                }
            },
            dimensionName(){ 
                return state.dimensionName 
            },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        },
        methods: {
            getReduceName(name){
                return name.slice(0,3)+'.'
            },
            translate(word) {
                switch (word) {
                    case 'Nacional':
                        return this.$t("national")
                        break;
                     default:
                        return word
                        break;
                }
            },
            toTops(){ 
                this.$router.push( { name: 'comercialtops' } ) 
            },
            toKpis(){ 
                this.$router.push({name:'kpis'})
            },
            toRepresentants(){
                this.$router.push({name:'representants'})
            },
            gotocomercialfilters(){
                this.$router.push({name:'comercialfilters'})
            },
            openFacturation(){
                this.facturation = !this.facturation
                this.metroscuadrados = false
            },
            openMeters() {
                this.metroscuadrados = !this.metroscuadrados
                this.facturation = false
            },
            openPreciomedio() {
                this.preciomedio = !this.preciomedio
                this.margen = false
            },
            openMargen() {
                this.margen = !this.margen
                this.preciomedio = false
            },
            toClients(){
                this.$router.push({name: 'clients'})
            },
            toVisits(){
                this.$router.push({name:'visits'})
            },
            openZone(zone, zonetype) {
                actions.setSaleTypeSelected(zonetype.toUpperCase())
                actions.setZone(zone)
                actions.setIdZone(zone.Id.toUpperCase())
                actions.setFilterZoneComercial(zone.Id.toUpperCase())
                actions.setFilterZoneComercialName(zone.Name)
                actions.setComercialSelected(null)
                if (zonetype === '24C8C714394211EA966B005056AEAA71') {
                    actions.setZoneSelected('Nacional')
                    actions.setTypeComercialsSelected('Nacional')
                }
                else {
                    actions.setZoneSelected('Exportación')
                    actions.setTypeComercialsSelected('Exportación')
                }
                setTimeout(() => {
                    this.$refs['zone'].request();
                }, 1);
            }
        },
        watch: {
            comercialSelected() {
                setTimeout(() => {
                    this.$refs['zone'].request();
                }, 100);
            }
        },
        mounted(){
            this.$refs['zone'].request()
        }
    }
</script>
